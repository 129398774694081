// Load Bootstrap JS
import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

// App code
// console.log(`Hello ${process.env.var1}`);

let hamburger_open = false;
const Navbar = document.querySelector('#navbar');
const hamburger = document.querySelector('.hamburger');
const isIndex = document.getElementById('index');
var navMain = document.getElementById('nav-main');

hamburger.addEventListener('click', function(){
    
    if(hamburger_open){ // to close
        hamburger.classList.remove('is-active');
        hamburger_open = false;
    } else {
        hamburger.classList.add('is-active');
        hamburger_open = true;
    }

});

var _y = window.pageYOffset;

if(isIndex){
    
    navMain.addEventListener('show.bs.collapse', function () {
        Navbar.classList.remove('bg-transparent');
        Navbar.classList.add('bg-primary');
    });
    navMain.addEventListener('hide.bs.collapse', function () {
        if(_y <= 100){
            Navbar.classList.remove('bg-primary');
            Navbar.classList.add('bg-transparent');
        }
    });
    window.addEventListener('scroll', function() {
        _y = window.pageYOffset;
        if(Navbar){
            if(_y > 100) { // down
                Navbar.classList.remove('bg-transparent');
                Navbar.classList.add('bg-primary');
            } else {
                Navbar.classList.remove('bg-primary');
                Navbar.classList.add('bg-transparent');
            }
        }
    }, {passive: true});    

} else {
    Navbar.classList.remove('bg-transparent');
    Navbar.classList.add('bg-primary');
}
